#header {
	height: 73px;
    min-height: 73px;
}
#logo{
	float: left;
	min-height: 97px;
	line-height: 97px;

	@media(max-width: 425px){
		min-height: 45px;
		line-height: 45px;
	}
	@media(max-width: 375px){
		min-height: 45px;
		line-height: 45px;
	}
}
#logo img {
    position: relative;
    z-index: 2;
}
#language .icon_oneweb{
	height: 22px;
	width: 30px;
	float: left;
}
#language .vi{
	background-position: 0 2px;
}
#language .en{
	background-position: -44px 2px;
}
#language .ch{
	background-position: -90px 2px;
}
#language .jp{
	background-position: -139px 2px;
}

.num_notice {
    background-color: #fa4e20;
    border-radius: 5px;
    border: 1px solid #fff;
    padding: 1px 4px;
    color: #fff;
    float: left;
    height: 18px;
    font-size: 11px;
    position: absolute;
    top: -9px;
    right: -7px;
    line-height: 13px;
}

@media (min-width: 1200px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 768px) and (max-width: 991px) {
	.navbar-nav .open .dropdown-menu{
		    position: absolute!important;
		    right: 0!important;
		    float: left;
		    left: inherit;
		    top: 100%;
		    z-index: 1000;
		    float: left;
		    min-width: 160px;
		    padding: 5px 0;
		    margin: 2px 0 0;
		    font-size: 14px;
		    text-align: left;
		    list-style: none;
		    background-color: #fff;
		    -webkit-background-clip: padding-box;
		    background-clip: padding-box;
		    border: 1px solid #ccc;
		    border: 1px solid rgba(0,0,0,.15);
		    border-radius: 4px;
		    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
		    box-shadow: 0 6px 12px rgba(0,0,0,.175);
		}
}
@media (min-width: 768px){
	.navbar-nav>li>a {
	    padding-top: 9px;
	    padding-bottom: 9px;
		color: #000;
		font-weight: bold;
    	white-space: nowrap;
	}
	#language .icon_oneweb{
		margin-right: 10px;
	}
}
@media (max-width: 767px) {
	.navbar-nav .open .dropdown-menu{
	    position: absolute!important;
	    right: 0!important;
	    float: left;
	    left: inherit;
	    top: 100%;
	    z-index: 1000;
	    float: left;
	    min-width: 160px;
	    padding: 5px 0;
	    margin: 2px 0 0;
	    font-size: 14px;
	    text-align: left;
	    list-style: none;
	    background-color: #fff;
	    -webkit-background-clip: padding-box;
	    background-clip: padding-box;
	    border: 1px solid #ccc;
	    border: 1px solid rgba(0,0,0,.15);
	    border-radius: 4px;
	    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
	    box-shadow: 0 6px 12px rgba(0,0,0,.175);
	}
	.nav-login ul.nav li{
		float: left;
	}
	.navbar-nav>li>a{
		display: inline-block;
		padding: 10px 4px;
	}
	#language .icon_oneweb{
		margin: 3px;
	}
}

@media (max-width: 480px) {
	
}
@media (max-width: 425px) {
	.nav-tabs.tab_oneweb>li{
		width: 100%;
	}
	#logo img{
		width: 100%;
	}
	a.pre_line {
	    white-space: pre;
		border: none;
	    width: 100%;
	    display: inline-block;
	}
}
@media (max-width: 375px){
	#logo img{
		width: 100%;
	}
}