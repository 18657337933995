.sidebar_member {
    font-size: 99%;
}
.bg_title {
    line-height: 40px;
    background-color: #fdb812;

	strong{
		color: #fff;
	}
}
#s_listbaogia{
	li{
		height: 35px;
		line-height: 35px;
	
		 a {
		    display: inline-block;
			white-space: nowrap;
   			overflow: hidden;
		}
		
		&.active {
		    background-color: #fff7d0;
		
		}
	}
	
	
} 
