.navbar-oneweb {
    -webkit-background-color: #fff7d0;
	-o-background-color: #fff7d0;
	-moz-background-color: #fff7d0;
	-ms-background-color: #fff7d0;
	background-color: #fff7d0;
	-webkit-box-shadow: 0 2px 3px #b4b4b4;
	-o-box-shadow: 0 2px 3px #b4b4b4;
	-moz-box-shadow: 0 2px 3px #b4b4b4;
	-ms-box-shadow: 0 2px 3px #b4b4b4;
	box-shadow: 0 2px 3px #b4b4b4;
	
	.navbar-collapse,
	.navbar-form {
	    border-color: #fecd5c;
	}
}
