@charset "utf-8";

$primary-color: #464646;
$link-color: #0872ba;
$link-color-hover: #040707;

@font-face {
  font-family: 'verdana';

  src: url('../fonts/verdana.eot');
  src: url('../fonts/verdana.eot?#iefix') format('embedded-opentype'), url('../fonts/verdana.woff2') format('woff2'), url('../fonts/verdana.woff') format('woff'), url('../fonts/verdana.ttf') format('truetype'), url('../fonts/verdana.svg#verdana') format('svg');
}

body {
	line-height	: 24px;
	font-size: 15px;
	font-family: verdana,arial,sans-serif;
	margin: 0;
	padding: 0;
	color: $primary-color;
}
a{
	&:focus,
	&:hover{
		text-decoration: none;
	}
}
* {
	outline: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
span,p{
	font-weight: normal;
}
.position_relative{
	position: relative;
}
blockquote{
	quotes: none;

	&:before{
		content: none;
	}

	&:after{
		content: none;
	}
}
q {
	quotes: none;

	&:before{
		content: none;
	}

	&:after{
		content: none;
	}
}
.highlight{
  background-color: yellow;
}
img {
	border: 0px;
	outline: none;
}
a {
	color: #0872ba;
	text-decoration: none;
	outline: none;
}
.font-bold{
	font-weight:bold;
}
.color_red{
	color: #ca1f27;
}
.color_blue{
	color: #0872ba;
}
.color_black{
	color: #333;
}
.m-t-15{
	margin-top: 15px;
}
.m-b-15{
	margin-bottom: 15px;
}
.m-b-20{
	margin-bottom: 20px;
}
.m-t-20{
	margin-top: 20px;
}
.m-y-15{
	margin-bottom: 15px;
	margin-top: 15px;
}
.m-x-15{
	margin-left: 15px;
	margin-right: 15px;
}
.p-r-15{
	padding-right: 15px;
}
.p-t-15{
	padding-top: 15px;
}
.p-b-15{
	padding-bottom: 15px;
}
.p-y-15{
	padding-bottom: 15px;
	padding-top: 15px;
}
.p-x-15{
	padding-right: 15px;
	padding-left: 15px;
}
.border_block{
	border: 1px solid #cacaca;
}
.border_bold{
	border: 2px solid #ccc;
}
.border_top_ccc{
	border-top: 1px solid #ccc;
}
.border_bottom_ccc{
	border-bottom: 1px solid #ccc;
}
.border_right_ccc{
	border-right: 1px solid #ccc;
}
.border_left_ccc{
	border-left: 1px solid #ccc;
}
.border_b_dot_ccc{
	border-bottom: 1px dotted #ccc;
}
.border_bottom_ececec{
	border-bottom: 1px solid #ececec;
}
.border_ececec{
	border: 1px solid #ececec;
}
.border_top_ececec{
	border-top: 1px solid #ececec;
}
.border_right_ececec{
	border-right: 1px solid #ececec;
}
.border_left_ececec{
	border-left: 1px solid #ececec;
	margin-left: -1px;
}
.border_img {
	img{
		padding: 4px;
		border: 1px solid #ccc;
		max-width: 100%;
		height: auto;
	}
}
.box_shawdow{
	-webkit-box-shadow: 0 3px 5px #666;
	-o-box-shadow: 0 3px 5px #666;
	-moz-box-shadow: 0 3px 5px #666;
	-ms-box-shadow: 0 3px 5px #666;
	box-shadow: 0 3px 5px #666;
}
.border_ccc{
	border: 1px solid #ccc;
}
.bg_hong_nhat{
	background-color: #fff7d0;
}
.bg_white{
	background-color: #fff;
	float: left;
	width: 100%;
}
.bg_gray{
	background-color: #f3f3f3;
}
.bg_blue{
	background-color: #0e64af;
}
.color_green{
	color: #00a652;
}
.bg_green{
	background-color: #00a652;
	padding-top: 3px;
	padding-bottom: 3px;
	color: #fff;
}
.btn-default{
	&:focus,
	&:hover{
		background-color: #e8a302;
		background-position: 0 -33px;
		color: #fff;
	}
}
.btn_upgrade{
	background-color: #00a652;
	background-image: none;
	color: #fff;
	text-shadow: none;
}
.btn_submit{
	background-color: #fdb812;
	background-image: none;
	text-shadow: none;
	color: #fff;
	border: none;
	margin: 5px 0;
	min-width: 53px;

	&:hover,
	&:active,
	&:focus,
	&:active:focus{
		color: #333;
		background-color: #ffdf3e;
	}
}

.btn_cancel {
	background-color: #ccc;
	background-image: none;
	border: none;

	&:active,
	&:focus,
	&:active:focus,
	&:hover{
		color: #333;
		background-color: #eee;
	}
}
.btn_registion {
	background-color: #fdb812;
	background-image: none;
	text-shadow: none;
	color: #fff;
	font-size: 22px;
	border: none;
	padding: 4px 40px;

	&:hover,
	&:active,
	&:focus,
	&:active:focus{
		color: #333;
		background-color: #ffdf3e;
	}
}
.btn_update_big{
	padding: 10px 2em;
	font-weight: 100;
	font-size: 18px;
}
.btn_update{
	background-color: #fdb812;
	background-image: none;
	color: #fff;
	border: none;
	font-weight: bold;
	text-shadow: none;

	&:hover,
	&:focus,
	&:active:focus,
	&:active{
		background-color: #fdb812;
	}
}
.btn_search{
	background-color: #fdb812;
	color: #fff;
	border: none;
	font-weight: bold;
	background-position: -31px -62px;
	padding-left: 34px;
	text-shadow: none;
	margin-bottom: 3px;

	&:hover,
	&:active:focus,
	&:focus{
		background-position: -31px -62px!important;
		color: #fff;
		background-color: #e8a302;
	}

	@media (min-width: 1200px) {
		float: left;
	}
}

.btn_show {
	background-color: #fdb812;
	background-image: none;
	border: none;
	padding: 3px 5px;
	color: #fff;
	border-radius: 0;
	text-shadow: none;
}

.centered{
	float: none!important;
}
.readmore:before {
	content: '';
	width: 0;
	left: 0;
	border-top: 6px solid #288ad6;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	display: inline-block;
	vertical-align: middle;
	margin: 0 5px 0 5px;
}
.readmore.up:before {
	content: '';
	width: 0;
	left: 0;
	border-bottom: 6px solid #288ad6;
	border-top: none;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	display: inline-block;
	vertical-align: middle;
	margin: 0 5px 0 5px;
}
#flashMessage {
	margin: 0 auto;
	width: 100%;
	padding: 5px;
	position: fixed;
	top: 0;
	z-index: 10000;

	span{
		border-radius: 3px;
		padding-left: 32px;
		display: block;
		width: 470px;
		line-height: 35px;
		margin: 0 auto;
		color: #fff;
		box-shadow: 0 0 3px #aaa;
	}

	&.success {
			span {
			background-image: url('img/success.png');
			background-position:  8px center;
			background-repeat: no-repeat;
			background-color: green;
		}
	}

	&.error {
		span {
			background-image: url('img/error.png');
			background-position:  8px center;
			background-repeat: no-repeat;
			background-color: #D31010;
		}
	}

}

.error-message {
	color: red;
	line-height: 22px;
	float: left;
	text-align: left;
	padding-left: 5px;
}

/**** #ajax_loading ****/
#message_top {
	display: none;
	top: 20px;
	float: right;
	position: fixed;
	z-index: 100000;
	width: 100%;
	text-align: center;

	#loading{
		width: 160px;
		margin: 0 auto;
		padding: 30px 10px 15px;
		border-radius: 3px;
		box-shadow: 0px 0px 4px #535353;
		opacity: 0.9;
		background: #fff url(img/ajax-loading.gif) center 10px no-repeat;
	}
}
.paginator {
/* 	border-top: 1px solid #eee; */
	text-align: right;
	height: 40px;
	line-height: 20px;
	display: block;
	padding-right: 5px;
	padding-top: 10px;

	span {
		margin: 1px;
	}

	span a{
		padding: 2px 5px;
		background: #eee;
		border-radius: 5px 0 5px 0; /* Opera 10.5, IE 9, Saf5, Chrome */;
		border: 1px solid #D3D3D3;
		color: #535353;
	}
	span.current {
		padding: 2px 5px;
		background: #eee;
		border-radius: 5px 0 5px 0; /* Opera 10.5, IE 9, Saf5, Chrome */;
		border: 1px solid #D3D3D3;
		color: #0872ba;
	}
	a{
		&:hover {
			font-weight: bold;
			border: 1px solid #C6C6C6;
			background: #ddd;
			color: #535353;
		}

		.disabled-page {
			color: #777;
		}

		.disabled-page{
			&:hover {
				text-decoration: none;
				cursor: default;
			}
		}
	}
}

.inline {
	display: inline!important;
}
.inline_block {
	display: inline-block!important;
}

.alert {
	margin-top: 0;
	float: left;
	padding: 2px 10px;
}

.line_height22{
	line-height: 22px;
}
.size1_5em {
	font-size: 1.5em;
}

.size2em {
	font-size: 2em;
}
.fix_two_row{
	height: 44px;
	margin-bottom: 0;
	display: inherit;
	overflow: hidden;
}

.icon_oneweb{
	background-image: url('img/icon.png');
	display: inline-block;
}
span.icon_oneweb.icon_tree {
	background-position: 0 -124px;
	width: 30px;
	height: 30px;
	float: left;
}
.border_radius{
	border-radius: 5px;
}
.im{
	color: red;
}
.create_date{
	color: #888;
}

#call {
	position: fixed;
	bottom: 0;
	display: block;
	left: 104px;
	background-color: #ec1c23;
	z-index: 123;
	padding: 6px;
	border-radius: 5px 5px 0 0;
	text-align: center;

	/*&:before{
		content: '';
		border-top: 13px solid transparent;
		border-left: 13px solid #ec1c23;
		border-right: 13px solid transparent;
		position: absolute;
		top: -9px;
		left: 0;
	}
	&:after{
		content: '';
		border-bottom: 13px solid transparent;
		border-left: 13px solid #ec1c23;
		border-right: 13px solid transparent;
		position: absolute;
		bottom: -10px;
		left: 0;

	}*/
	a {
		text-decoration: none;
	}
	a:hover{
		text-decoration: none;
	}
	span {
		color: #fff;
		font-size: 15px;
		vertical-align: middle;
		font-weight: bold;
		letter-spacing: -0.5px;
	}
	.hotline {
		width: 21px;
		margin-right: 8px;
	}
}
