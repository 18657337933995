.birthday{
	position: relative;
}
.hasDatepicker{
	background-color: transparent!important;
}
.icon_calendar {
    width: 23px;
    height: 25px;
    background-position: -196px -32px;
    position: absolute;
    right: 22px;
    top: 3px;
	z-index: 0;
	-webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    direction: ltr;
}
.item_s_j{
	border-radius: 5px;
	margin:0 5px 5px 0;
    padding: 2px 5px;
    display: inline-block;
}
.err_update {
    background: linear-gradient(#f27257,#d22028);
    color: #fff;
    padding: 3px;
    border-radius: 100%;
    font-size: 11px;
}
.list_group {
	li{
		list-style-image: url(img/check.gif);
	    line-height: 30px;
	}
}
.text_color_999 {
    color: #999;
}
.tabel_translate_temp{
	float: left;
	width: 100%;
}
.text_member_type{
	color: #fff;
    white-space: nowrap;
	padding: 2px 10px;
}
.f-size16{
	font-size: 16px;
}

.email .alert,
.password .alert{
	display: none;
}

#swap_lang{
	@media(min-width: 768px){
		float: left;
		transform: rotate(90deg);
    	margin-top: 33px;
	}
	@media(max-width: 767px){
		text-align: center;
	}
}
.thumb_hover{
	
	a{
		display: block;
	    position: absolute;
	    top: 0;
		background: rgba(0,0,0,0.4);
	    transition: 0.5s;
		float: left;
	    width: 100%;
		color: red;
 		height: 100%;
		
		>span{
	        top: 42%;
		    display: inline-block;
		    position: absolute;
		    width: 100%;
		    left: 0;

			>span{
				border: 1px solid #ccc;
				padding: 4px;
    			border-radius: 4px;
				background-color: #fdb812;
			}
		}
	}
		
	a.hover_avatar {
		display: none;
		transition: 0.5s;
		color: #fff;
	}
	&:hover{
		
		a.hover_avatar{
			display: block;
		    position: absolute;
		    top: 0;
			background: rgba(0,0,0,0.4);
		    transition: 0.5s;
			float: left;
		    width: 100%;
			color: red;
	 		height: 100%;
			
			>span{
		        top: 38%;
			    display: inline-block;
			    position: absolute;
			    width: 100%;
			    left: 0;
				
				>span{
					border: 1px solid #ccc;
					border: 1px solid #ccc;
					padding: 4px;
	    			border-radius: 4px;
				}
			}
		}
	}
	
}
