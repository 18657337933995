#box_baogia{
	white-space: pre-line;
}
.icon_new_project{
	width: 25px;
    position: relative;
    float: left;
    height: 23px;
    background-position: -246px -131px;
}
#clk_more_info{
	color: #888;
}
.field_abs {
    position: absolute;
    top: 4px;
    right: 22px;
	font-weight: bold;
	background-color: #fff;
    padding-left: 10px;
}

label[for='PostTypeNewmember'] {
    margin-right: 18%;   
	margin-left: 5px;
	font-weight: normal;
}
label[for='PostTypeOldmember'] {
	margin-left: 5px;
	font-weight: normal;
}
.icon_more2 {
    border-radius: 100%;
    padding: 0;
    height: 25px;
    width: 25px;
    line-height: 22px;
    font-size: 24px;
    color: #888;
    font-weight: normal;
    font-family: inherit;
}

.m-r-1{
    margin-right: 1px;
}
.input_file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.input_file:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}
.input_file:hover + label{
	cursor:pointer;
}
.input_file + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
}
.input_file_1 + label {
    color: #fff;
	font-weight: bold;
    background-color: #fdb812;
	padding: 3px 10px;
}
.blur_filter{
	filter: blur(5px);
	-o-filter: blur(5px);
	-webkit-filter: blur(5px);
	-ms-filter: blur(5px);
	-moz-filter: blur(5px);
	position: relative;
}
.blur_overlay{
	background: transparent;
    position: absolute;
    float: left;
    width: 100%;
    height: 100%;
    top: 0;
	left:0;
}
@media (min-width: 1200px) {
	.lg_fix_bottom{
		position: absolute;
		bottom: 0;
		right: 1px;
	}
	.lg-p-b-60 {
	    padding-bottom: 69px;
	}
	.p-x-40{
		padding-left: 35px;
		padding-right: 35px;
	}
	.m-t-lg-15{
		margin-top: 15px;
	}
	.m-b-lg-15{
		margin-bottom: 15px;
	}
	.p-y-40{
		padding-top: 40px;
		padding-bottom: 40px;
	}
	div#search_advance {
	    float: left;
	    width: 100%;
	    height: 78px;
	}
	.btn_search{
		float: left;
	}
	.table_work th {
	    white-space: nowrap;
	}
	.info_hire img {
	    height: 284px;
	}
	#btn_close_comment{
		margin-left: 15px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.info_hire img {
	    height: 262px;
	}
	.m-t-md-15 {
		margin-top: 15px;
	}
	.m-b-md-15 {
		margin-bottom: 15px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.info_hire img {
	    height: 284px;
	}
	.m-t-sm-15 {
		margin-top: 15px;
	}
	.m-b-sm-15 {
		margin-bottom: 15px;
	}
}
@media (min-width: 768px){
}
@media (max-width: 767px) {
}

@media (max-width: 480px) {
	
}
@media (max-width: 425px) {
label[for='PostTypeNewmember'] {
	    margin-right: 4%;
	}
	.box_popup{
		top:0;
	}
	.info_hire {
	    min-height: 164px;
	}
}
@media (max-width: 375px){
	label[for='PostTypeNewmember'] {
	    margin-right: 45%;
	}
}