.icon_red {
	float: left;
	height: 24px;
    width: 20px;
    background-position: -39px -33px;
}
.icon_lang {
    width: 15px;
    height: 19px;
    background-position: -125px -33px;
}
.icon_write {
    width: 15px;
    height: 19px;
    background-position: -164px -33px;
}
.nav-tabs.tab_oneweb>li.active>a, .nav-tabs.tab_oneweb>li.active>a:focus, .nav-tabs.tab_oneweb>li.active>a:hover {
    color: #fff;
    cursor: default;
    background-color: #fdb812;
	box-shadow:-1px 1px 1px #fdb812;
	-webkit-box-shadow:-1px 1px 1px #fdb812;
	-moz-box-shadow:-1px 1px 1px #fdb812;
	-ms-box-shadow:-1px 1px 1px #fdb812;
	-o-box-shadow:-1px 1px 1px #fdb812;

    border: 1px solid #fdb812;
}
.nav.tab_oneweb>li>a {
	font-weight: bold;
    padding: 5px 25px;
    background-color: #fff7d0;
	-webkit-box-shadow: 0 1px 9px -4px #000;
     -moz-box-shadow: 0 1px 9px -4px #000;
          box-shadow: 0 1px 9px -4px #000;
    margin-left: 10px;
    color: #000;
}
.home_notifications {
	.title_notifi{
		width: 112px;
	}

	.border_ccc {
	   	height: 35px;
    	padding: 5px;
	}
}
.f_size34{
	font-size: 34px;
}
.nav-tabs.tab_oneweb>li {
    margin-bottom: 0px;
}
.tab_oneweb li span.glyphicon {
    display: none;
}
.tab_oneweb li.active span.glyphicon {
    display: block;
	color: #fdb812;
    text-align: center;
    margin-top: -4px;
    margin-bottom: -11px;
	position: relative;
    z-index: 2;
}
.tab-content {
    background-color: #fff;
    position: relative;
    z-index: 1;
}
/* Large desktops and laptops */
@media (min-width: 1200px) {
	.lg_fix_bottom{
		position: absolute;
		bottom: 0;
		right: 1px;
	}
	.lg-p-b-60 {
	    padding-bottom: 69px;
	}
	.p-x-40{
		padding-left: 35px;
		padding-right: 35px;
	}
	.m-t-lg-15{
		margin-top: 15px;
	}
	.m-b-lg-15{
		margin-bottom: 15px;
	}
	.p-y-40{
		padding-top: 40px;
		padding-bottom: 40px;
	}
	div#search_advance {
	    float: left;
	    width: 100%;
	    height: 78px;
	}
	.table_work th {
	    white-space: nowrap;
	}
	.info_hire img {
	    height: 284px;
	}
	#btn_close_comment{
		margin-left: 15px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.info_hire img {
	    height: 262px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.info_hire img {
	    height: 284px;
	}
}
@media (min-width: 768px){
	a.pre_line {
	    padding-right: 10px;
	    border-right: 1px solid;
	    line-height: 14px;
	    height: 15px;
	    float: left;
	    margin-bottom: 7px;
	    margin-right: 9px;
	}
	a.pre_line:last-child{
		border-right: none;
	}
	.navbar-nav>li>a {
	    padding-top: 9px;
	    padding-bottom: 9px;
		color: #000;
		font-weight: bold;
    	white-space: nowrap;
	}
	.adv_block img.img-responsive {
	    display: inline-block;
	}
}
@media (max-width: 767px) {
	a.pre_line {
	    padding-right: 5px;
	    border-right: 1px solid;
	    line-height: 14px;
	    height: 15px;
	    float: left;
	    margin-bottom: 7px;
	    margin-right: 5px;
	}
	a.pre_line:last-child{
		border-right: none;
	}
	.table-responsive>.table>tbody>tr>td,
	.table-responsive>.table>tbody>tr>th,
	.table-responsive>.table>tfoot>tr>td,
	.table-responsive>.table>tfoot>tr>th,
	.table-responsive>.table>thead>tr>td,
	.table-responsive>.table>thead>tr>th{
		white-space: normal;
	}
	.nav.tab_oneweb>li>a{
		margin-left: 0;
	}
}

@media (max-width: 480px) {

}
@media (max-width: 425px) {
	.nav-tabs.tab_oneweb>li{
		width: 100%;
	}
	.tab_oneweb li.active span.glyphicon{
		color: transparent;
	}
	.box_popup{
		top:0;
	}
	.info_hire {
	    min-height: 164px;
	}
	a.pre_line {
	    white-space: pre;
		border: none;
	    width: 100%;
	    display: inline-block;
	}
}
@media (max-width: 375px){
	label[for='PostTypeNewmember'] {
	    margin-right: 45%;
	}
}

.angle-down-circle {
	.fa-angle-down {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    border: 1px solid #ccc;
    line-height: 18px;
    margin-bottom: 15px;
	}

	&:hover {
		.fa-angle-down {
			color: #0872ba;
	    border: 1px solid #0872ba;

		}

	}
}