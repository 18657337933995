/* jQuery.Rating Plugin CSS - http://www.fyneworks.com/jquery/star-rating/ */
div.rating-cancel,div.star-rating{float:left;width:17px;height:15px;text-indent:-999em;cursor:pointer;display:block;background:transparent;overflow:hidden}
/* div.rating-cancel,div.rating-cancel a{background:url(delete.gif) no-repeat 0 -16px;} */
div.star-rating,div.star-rating a{background:url(img/rate_star.png) no-repeat 0 0px}
div.rating-cancel a,div.star-rating a{display:block;width:16px;height:100%;background-position:0 0px;border:0}
div.star-rating-on a{background-position:0 -16px!important}
div.star-rating-hover a{background-position:0 -16px}
/* Read Only CSS */
div.star-rating-readonly a{cursor:default !important}
/* Partial Star CSS */
div.star-rating{background:transparent!important;overflow:hidden!important}
/* END jQuery.Rating Plugin CSS */

.rating-block{
	display: flex;
}
span.rate_num {
    padding: 0 10px;
    background-color: #fdb812;
    color: #fff;
    display: inline-block;
}
.rating_star{
	background-image: url(img/star_rate.png);
	background-repeat: no-repeat;
	display: inline-flex;
 	width: 112px;
    height: 24px;
}
.border_bt_yellow{
	border-bottom: 1px solid #fdb912;
}
span.rating-box {
    background-position: 0 3px;
}
span.rating {
    background-position: 0 -20px;
}
.table_work {
	border: 2px solid #ccc;
}