h1 {
	font-size: 24px;
	text-transform: none;
    font-weight: 400;
}

h2 {
	font-size: 20px;
	font-weight: 400;
}

h3 {
	font-size: 17px;
}

h4, h5, h6 {
	font-size: 15px;
}

a {
	color: $link-color;

	&:hover {
		color: $link-color-hover;
		text-decoration: none;
	}
}

.dropdown .glyphicon {
    top: 5px;
}

.form-control {
    font-weight: normal;
}
.well{
	margin-bottom: 0;
}
.modal-open{
	overflow: initial;
}
.modal-open .modal{
	@media (min-width: 768px){
		margin-right: -17px;
	}
}
.row_xs,.row_sm{
    margin-right: -15px;
    margin-left: -15px;
}
.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
    background-color: transparent;
}
.navbar{
	border-radius: 0;
}
.navbar-oneweb .navbar-toggle {
    border-color: #cc2026;
}
.navbar-oneweb .navbar-toggle .icon-bar {
    background-color: #cc2026;
}
.nav>li>a:focus, .nav>li>a:hover {
    text-decoration: none;
	color: #ca2126;
    background-color: transparent;
}
.navbar-oneweb .navbar-nav>.active>a, .navbar-oneweb .navbar-nav>.active>a:focus, .navbar-oneweb .navbar-nav>.active>a:hover {
    color: #ca2126;
    background-color: transparent;
}
.nav-top.nav-login >ul.nav > li >a {
    color: #ca2126;
    display: inline-block;
    padding-left: 0;
    padding-top: 0;
	height: 17px;
	font-weight: bold;
    padding-bottom: 0;
}
.nav-top.nav-login ul.nav > li.dropdown-menu > a{
	padding-left: 15px;
}