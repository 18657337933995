.unread a{
	font-weight:bold;
}
.icon_new_message {
	width: 27px;
    position: relative;
    float: left;
    height: 23px;
    background-position: -170px -131px;
}
.box_popup {
    position: absolute;
    top: 5%;
    width: 100%;
}
#block_message{
/* 	height: 500px; */
/* 	overflow-y: scroll; */
/* 	overflow-x: hidden; */
/*     margin-right: -15px; */
/*     padding-right: 15px; */
}
.msg_viewed .author a{
	color: #333;
}
.msg_new .author{
}
.msg_new .content{
}
.panel {
    position: relative;

	>.panel-heading:after, 
	>.panel-heading:before {
	    position: absolute;
	    top: 11px;
	    left: -16px;
	    right: 100%;
	    width: 0;
	    height: 0;
	    display: block;
	    content: " ";
	    border-color: transparent;
	    border-style: solid solid outset;
	    pointer-events: none;
	}
	
	>.panel-heading:before {
	    border-right-color: #ddd;
	    border-width: 8px;
	}
	
	>.panel-heading:after {
	    border-width: 7px;
	    border-right-color: #fff;
	    margin-top: 1px;
	    margin-left: 2px;
	}
}
.panel-default>.panel-heading{
	background: transparent;
    border: none;
    padding: 0;
}


.pull-right .panel>.panel-heading_right:after, .panel>.panel-heading_right:before {
	left: 100%;
	right: 16px;
	position: absolute;
    top: 11px;
    width: 0;
    height: 0;
    display: block;
    content: " ";
    border-color: transparent;
    border-style: solid solid outset;
    pointer-events: none;
}
.pull-right .panel>.panel-heading_right:before {
    border-left-color: #ddd;
    border-width: 8px;
}
.pull-right .panel>.panel-heading_right:after {
    border-width: 7px;
    border-left-color: #fff;
    margin-top: 1px;
    margin-left: 0;
}
.hover_gray:hover {
    cursor: pointer;
    background-color: #fffcec;
}
#page_message{
    position: relative;
}
.delete_msg {
	display: none;
    position: absolute;
    right: 3px;
    top: 4px;
}
.active .delete_msg,
.delete_conver{
	display:block;
	cursor:pointer;
	color: #ca1f27;
    text-decoration: none;
}
.delete_conver:hover{
	color: red;
	text-decoration: none;
}