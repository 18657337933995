#footer{
	background-color: #fff7d0;
    padding-bottom: 33px;
	
	h4{ 
	 	border-bottom: 1px solid #a1a1a1;
    	padding-bottom: 7px;
	
		strong{
			color: #ca2126;
		}
	}
	
	table {
		td {
			@media (max-width: 767px) {
			    float: left;
			    width: 100%;
			}
		}
	}
}

.icon_f_facebook {
    background-position: -172px -67px;
    width: 40px;
    height: 40px;
}

.icon_f_googleplus {
    background-position: -214px -67px;
    width: 40px;
    height: 40px;
}

.icon_f_twitter {
    background-position: -253px -67px;
    width: 40px;
    height: 40px;
}

.icon_f_pinreset {
    background-position: -172px -67px;
    width: 40px;
    height: 40px;
}

#back-top {
	bottom: 25px;
	right: 15px;
	position: fixed;
	z-index: 9999;

	a {
		color: #BBBBBB;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 12px;
		line-height: 26px;
		display: block;
		text-align: center;
	}
	
	a:hover {
		color: #000000;
		
		span {
			background-color: #0872ba;
		}
	}
	
	span {
		background: #024776 url("img/up-arrow.png") no-repeat scroll center center;
		border-radius: 5px;
		width: 45px;
		height: 45px;
		display: block;
		margin-bottom: 7px;
		
		@media (max-width: 767px){
			margin-bottom: 45px;
		}
	}
}

.design {
	color: initial;

	span {
		color: initial;
		font-weight: bold;

		span {
			color: #F6D901;
		}
	}
}