#btn_gopy {
    position: fixed;
    left: 15px;
    background-color: #ec1c23;
    bottom: 0;
	padding: 7px 15px;
    text-transform: uppercase;
    border-radius: 5px 5px 0 0;
    color: #fff;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    font-weight: bold;
	z-index: 9;
	
	&:hover {
		text-decoration: none;
	}
}